
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmChip from '@/components/shared/TmChip.vue'
import RolesDropdown from '@/components/pages/admin/workspace/roles/RolesDropdown.vue'

export default defineComponent({
  name: 'RolesTable',
  components: {
    TmChip,
    TmTable,
    RolesDropdown,
  },
})
