
import type { PropType } from 'vue'
import { computed, defineComponent, nextTick, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import { useModals } from '@/compositions/modals'

type Column = {
  value: string;
  text: string;
  hidden?: boolean;
  hiddenLabel?: boolean;
  required?: boolean;
}

export default defineComponent({
  name: 'TmDropdownVisibility',
  components: { TmSearch, TmTooltip, TmButton, TmDropdown },
  props: {
    modelValue: {
      type: Array as PropType<Column[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    hideColumnsAdditionalButtons: {
      type: Boolean,
    },
  },
  emits: ['click-column', 'update:modelValue'],
  setup(props, context) {
    const { openModal } = useModals()
    const query = ref('')
    const isHidable = (col: Column) => col.text || col.hiddenLabel
    const filteredColumns = computed(() => {
      return props.modelValue.filter((item: Column) => isHidable(item))
    })
    const isAllShown = computed(() => {
      return filteredColumns.value.filter((item: Column) => !item.hidden).length === filteredColumns.value.length
    })
    const toggleAll = () => {
      const newVal = props.modelValue.map((item: Column) => {
        if (isHidable(item)) {
          return {
            ...item,
            hidden: item.required ? false : isAllShown.value,
          }
        }
        return item
      })
      context.emit('update:modelValue', newVal)
    }
    const toggleColumn = (col: Column) => {
      const newVal = props.modelValue.map((item: Column) => {
        if (item.value === col.value) item.hidden = !col.hidden

        return item
      })
      context.emit('click-column', { name: col.value, value: !col.hidden })
      context.emit('update:modelValue', newVal)
    }
    const searchField = ref()
    const focusSearch = async () => {
      await nextTick()
      await nextTick()
      searchField.value.$el.querySelector('input').focus()
    }
    const onDropdownChange = (opened: boolean) => {
      if (opened) {
        focusSearch()
      }
    }

    return {
      query,
      filteredColumns,
      isAllShown,
      toggleAll,
      toggleColumn,
      searchField,
      onDropdownChange,
      openModal,
    }
  },
})
