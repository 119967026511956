
import type { PropType } from 'vue'
import { defineComponent, ref, computed } from 'vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import TmDropdownColumns from '@/components/shared/dropdowns/TmDropdownColumns.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import Conditions from '@/components/pages/contacts/segments/details/Conditions.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import type { FiltersListType } from '@/definitions/shared/filters/types'
import TopFilterGrouping from '@/components/layout/topFilter/TopFilterGrouping.vue'
import TopFilterSorting from '@/components/layout/topFilter/TopFilterSorting.vue'
import type { GroupingItemType, SortingItemType } from '@/definitions/_general/tables/topFilter'

export default defineComponent({
  name: 'TopFilter',
  components: {
    TopFilterGrouping,
    TmButton,
    TmSearch,
    TmDropdownColumns,
    TopFilterSorting,
    TmTooltip,
    TmAutoSizePanel,
    TmAutoSizePanelMobileActivator,
    TopFilterButton,
    Conditions,
  },
  props: {
    groupingOptions: {
      type: Array as PropType<GroupingItemType[]>,
      default: () => [],
    },
    groupingValue: {
      type: Object as PropType<GroupingItemType>,
    },
    sortingOptions: {
      type: Array as PropType<SortingItemType[]>,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    changedFiltersCount: {
      type: Number,
    },
    hideSearch: {
      type: Boolean,
    },
    hideLeftCol: {
      type: Boolean,
    },
    hideExpand: {
      type: Boolean,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
    },
    search: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    disableRightLine: {
      type: Boolean,
    },
    expand: {
      type: Boolean,
      default: undefined,
    },
    expandedSearchInMobile: {
      type: Boolean,
    },
    hideColumnsAdditionalButtons: {
      type: Boolean,
    },
    visibleButtons: {
      type: Number,
      default: 4,
    },
    conditionsFilter: {
      type: Boolean,
    },
    filters: {
      type: Object as PropType<FiltersListType>,
      default: () => {},
    },
    defaultFilter: {
      type: Object as PropType<FiltersListType>,
      default: () => {},
    },
    showFilterDetails: {
      type: Boolean,
    },
    columnsLabel: {
      type: String,
      default: 'Columns',
    },
  },
  emits: ['update:grouping-value', 'click-column', 'update:filters', 'update:radio-value', 'update:model-value', 'update:columns', 'update:search', 'update:expand'],
  setup(props, context) {
    const { isSmMax, isLgMax } = useBreakpoints()
    const activeMobileSearch = ref(false)
    const onUpdateSearch = (val: string | null) => {
      context.emit('update:search', val || '')
    }
    const hasBottomSlot = !!context.slots['filter-line-bottom']
    const showFilterDetailsValue = ref(props.showFilterDetails)

    const toggleFilterDetails = () => {
      showFilterDetailsValue.value = !showFilterDetailsValue.value
    }

    const updateRadioValue = (name: string, value: any) => {
      context.emit('update:radio-value', { name, value })
    }
    const updateModelValue = (name: string, value: any) => {
      context.emit('update:model-value', { name, value })
    }

    const rightClass = computed(() => props.hideLeftCol ? 'filter-line__right--grow-1' : 'filter-line__right--grow-0')

    const showButtonsTextComputed = computed(() => !isLgMax.value)

    return {
      showButtonsTextComputed,
      toggleFilterDetails,
      updateRadioValue,
      updateModelValue,
      isSmMax,
      showFilterDetailsValue,
      activeMobileSearch,
      onUpdateSearch,
      hasBottomSlot,
      rightClass,
    }
  },
})
