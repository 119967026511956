import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08ac339e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-wrap" }
const _hoisted_2 = { class: "search-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_search = _resolveComponent("tm-search")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_virtual_scroll = _resolveComponent("tm-virtual-scroll")!
  const _component_filter_empty = _resolveComponent("filter-empty")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, { "filter-dropdown": "" }, {
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_tm_search, {
            modelValue: _ctx.query,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
            size: "small",
            placeholder: _ctx.searchPlaceholder
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createVNode(_component_tm_virtual_scroll, {
          height: 320,
          items: _ctx.filteredOptions
        }, {
          default: _withCtx(({ item, index }) => [
            (_openBlock(), _createBlock(_component_tm_dropdown_item, {
              key: index,
              icon: item.icon,
              label: item.label,
              onClick: ($event: any) => (_ctx.$emit('addFilter', item.label))
            }, null, 8, ["icon", "label", "onClick"]))
          ]),
          _: 1
        }, 8, ["items"]),
        (!_ctx.filteredOptions.length)
          ? (_openBlock(), _createBlock(_component_filter_empty, {
              key: 0,
              class: "pb-4"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_tm_button, {
          size: _ctx.size,
          "text-link": "",
          class: _normalizeClass(["primary--text", {
          'mr-2 px-2': _ctx.size === 'small'
        }])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass({
            'body-text-semi-bold-14': _ctx.size !== 'small'
          })
            }, _toDisplayString(_ctx.buttonText), 3),
            (!_ctx.hideIcon)
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: "tmi-arrow-drop-down-sharp",
                  class: "ml-0"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["size", "class"])
      ], true)
    ]),
    _: 3
  }))
}