
import type { SizeProp } from '@/definitions/shared/types'
import { defineComponent, ref, computed } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmVirtualScroll from '@/components/shared/TmVirtualScroll.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import FilterEmpty from '@/components/shared/filters/FilterEmpty.vue'
export default defineComponent({
  components: { TmDropdownItem, TmButton, TmDropdown, TmVirtualScroll, TmSearch, FilterEmpty },
  props: {
    items: {
      type: Array,
    },
    searchField: {
      type: String,
      default: '',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    buttonText: {
      type: String,
      default: 'More filters',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search filters',
    },
    size: {
      type: String as SizeProp<'small' | 'medium'>,
      default: 'small',
    },
    hideIcon: {
      type: Boolean,
    },
  },
  emits: ['addFilter'],
  setup(props) {
    const query = ref('')
    const internalOptions = ref<any>(props.items)
    const searchFunc = (queryString: string, list: any[]) => {
      if (query.value) {
        return list.filter((item) => {
          const searchField = props.searchField || props.optionLabel
          return typeof item === 'string'
            ? item.toLowerCase().includes(queryString.toLowerCase())
            : item[searchField].toLowerCase().includes(queryString.toLowerCase())
        })
      }
      return internalOptions.value
    }
    const filteredOptions = computed<any[]>(() => {
      if (query.value) {
        return searchFunc(query.value, internalOptions.value)
      }
      return internalOptions.value
    })

    return {
      query,
      filteredOptions,
    }
  },
})
