
import { defineComponent, ref } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import RolesFilter from '@/components/pages/admin/workspace/roles/RolesFilter.vue'
import RolesTable from '@/components/pages/admin/workspace/roles/RolesTable.vue'
import { useModes } from '@/compositions/modes'

type Role = {
  name: string;
  totalUsers: number;
  dateCreated: string;
  nameChip: any;
}

const rolesObj: {[key: string]: Role} = {
  'a1117166-82e3-4c36-b94f-2b230672e536': {
    name: 'Admin',
    nameChip: {
      title: 'system',
      color: 'gray',
    },
    totalUsers: 2,
    dateCreated: '2021-06-17T07:39:35.183Z',
  },
  'b1117166-82e3-4c36-b94f-2b230672e536': {
    name: 'Agent',
    nameChip: {
      title: 'system',
      color: 'gray',
    },
    totalUsers: 36,
    dateCreated: '2021-06-04T07:00:35.183Z',
  },
  'c1117166-82e3-4c36-b94f-2b230672e536': {
    name: 'Supervisor',
    nameChip: null,
    totalUsers: 2,
    dateCreated: '2021-06-02T09:12:35.183Z',
  },
  'd1117166-82e3-4c36-b94f-2b230672e536': {
    name: 'Accountant',
    nameChip: null,
    totalUsers: 4,
    dateCreated: '2021-06-26T12:30:35.183Z',
  },
}

const rolesNewObj: {[key: string]: Role} = {
  'a1117166-82e3-4c36-b94f-2b230672e536': {
    name: 'Admin',
    nameChip: {
      title: 'system',
      color: 'gray',
    },
    totalUsers: 1,
    dateCreated: '2021-06-17T07:39:35.183Z',
  },
  'b1117166-82e3-4c36-b94f-2b230672e536': {
    name: 'Agent',
    nameChip: {
      title: 'system',
      color: 'gray',
    },
    totalUsers: 1,
    dateCreated: '2021-06-17T07:39:35.183Z',
  },
}

export default defineComponent({
  name: 'WorkspaceRoles',
  components: {
    PageContent,
    RolesFilter,
    RolesTable,
  },
  props: {
    menuTitle: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const selected = ref<any[]>([])
    const rolesHeaders = ref([
      { text: 'Name', value: 'name' },
      { text: 'Users', value: 'totalUsers', width: '112px' },
      { text: 'Date created', value: 'dateCreated', width: '145px', format: (val: string) => formatDate(val) },
    ])

    const roles = ref(Object.keys(rolesObj).map((id) => ({
      id,
      ...rolesObj[id],
    })))

    const rolesNew = ref(Object.keys(rolesNewObj).map((id) => ({
      id,
      ...rolesNewObj[id],
    })))
    const onUpdate = (value: any[]) => {
      selected.value = value.filter((item: any) => !item.nameChip)
    }

    return {
      onUpdate,
      isEmptyMode,
      rolesHeaders,
      roles,
      rolesNew,
      selected,
    }
  },
})
