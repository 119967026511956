
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import type { GroupingItemType } from '@/definitions/_general/tables/topFilter'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmTooltip,
    TmDropdown,
    TmButton,
  },
  props: {
    showButtonText: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    modelValue: {
      type: Object as PropType<GroupingItemType>,
    },
    options: {
      type: Array as PropType<GroupingItemType[]>,
      default: () => [],
    },
  },
  emits: ['update:model-value'],
  setup() {
    const { isLgMax } = useBreakpoints()

    return {
      isLgMax,
    }
  },
})
