
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import type { SortingItemType } from '@/definitions/_general/tables/topFilter'
import type { PropType } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    showButtonText: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    options: {
      type: Array as PropType<SortingItemType[]>,
      default: () => [],
    },
  },
})
