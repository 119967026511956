import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_chip = _resolveComponent("tm-chip")!
  const _component_roles_dropdown = _resolveComponent("roles-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-selection": _withCtx((scope) => [
      _createVNode(_component_q_checkbox, {
        modelValue: scope.selected,
        "onUpdate:modelValue": ($event: any) => ((scope.selected) = $event),
        disable: !!scope.row.nameChip
      }, null, 8, ["modelValue", "onUpdate:modelValue", "disable"])
    ]),
    "body-cell-name": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: { name: 'base.admin.roles.details', query: { system: !!props.row.nameChip ? '1' : '' } },
          class: "fullwidth-link font-color-inherit font-weight-normal blue-on-hover mr-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.row.name), 1)
          ]),
          _: 2
        }, 1032, ["to"]),
        (props.row.nameChip)
          ? (_openBlock(), _createBlock(_component_tm_chip, {
              key: 0,
              color: props.row.nameChip.color,
              outline: "",
              static: "",
              "margin-x-on": ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.nameChip.title), 1)
              ]),
              _: 2
            }, 1032, ["color"]))
          : _createCommentVNode("", true)
      ])
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_roles_dropdown, _normalizeProps(_guardReactiveProps(props.row)), null, 16)
    ]),
    _: 1
  }))
}