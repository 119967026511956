
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useWorkspace from '@/compositions/admin/useWorkspace'

export default defineComponent({
  name: 'RolesDropdown',
  components: {
    TmDropdownItem,
    TmTableActionButton,
    TmDropdown,
  },
  setup() {
    const { openDuplicateRoleModal } = useWorkspace()

    return {
      openDuplicateRoleModal,
    }
  },
})
