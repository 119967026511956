import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_roles_filter = _resolveComponent("roles-filter")!
  const _component_roles_table = _resolveComponent("roles-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    title: "Roles",
    "menu-title": _ctx.menuTitle
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    before: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_roles_filter, { selected: _ctx.selected }, null, 8, ["selected"]),
      _createVNode(_component_roles_table, {
        "model-value": _ctx.selected,
        headers: _ctx.rolesHeaders,
        items: (_ctx.isEmptyMode) ? _ctx.rolesNew : _ctx.roles,
        "item-key": "id",
        "items-name": "roles",
        "show-selection": "",
        "onUpdate:modelValue": _ctx.onUpdate
      }, null, 8, ["model-value", "headers", "items", "onUpdate:modelValue"])
    ]),
    _: 3
  }, 8, ["menu-title"]))
}