import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_auto_size_panel_item = _resolveComponent("tm-auto-size-panel-item")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_panel_item, null, {
    "dropdown-item": _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        disable: _ctx.disabled,
        href: _ctx.href,
        "icon-rotate": _ctx.iconRotate,
        icon: _ctx.icon,
        label: _ctx.text,
        to: _ctx.to
      }, null, 8, ["disable", "href", "icon-rotate", "icon", "label", "to"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_button, {
        to: _ctx.to,
        disable: _ctx.disabled,
        href: _ctx.href,
        color: _ctx.color,
        "icon-only": _ctx.iconOnly,
        flat: _ctx.flat,
        size: _ctx.btnSize,
        target: _ctx.target
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 0,
                name: _ctx.icon,
                size: _ctx.iconSize,
                left: !_ctx.iconOnly,
                rotate: _ctx.iconRotate
              }, null, 8, ["name", "size", "left", "rotate"]))
            : _createCommentVNode("", true),
          (!_ctx.iconOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true),
          (_ctx.arrowDropdown)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 2,
                name: "tmi-arrow-drop-down-sharp",
                right: ""
              }))
            : _createCommentVNode("", true),
          (_ctx.tooltip)
            ? (_openBlock(), _createBlock(_component_tm_tooltip, {
                key: 3,
                "model-value": _ctx.showTooltip,
                "no-parent-event": _ctx.manualShowTooltip
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.tooltip), 1)
                ]),
                _: 1
              }, 8, ["model-value", "no-parent-event"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to", "disable", "href", "color", "icon-only", "flat", "size", "target"])
    ]),
    _: 1
  }))
}