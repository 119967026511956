
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useWorkspace from '@/compositions/admin/useWorkspace'

export default defineComponent({
  name: 'RolesFilter',
  components: {
    TopFilter,
    TopFilterButton,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openRolesDeleteModal, openRolesModal } = useWorkspace()
    const search = ref('')

    return {
      openRolesDeleteModal,
      openRolesModal,
      search,
    }
  },
})
