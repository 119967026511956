
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import FilterAdd from '@/components/shared/filters/FilterAdd.vue'
import { countries, addContactFilters } from '@/definitions/shared/filters/filtersLib/data'

export default defineComponent({
  components: {
    TmButton,
    DropdownFilterRadio,
    FilterAdd,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    defaultFilter: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['closeFiltersClick', 'update:filters', 'reset'],
  setup(props) {
    return {
      countries,
      addContactFilters,
    }
  },
})
