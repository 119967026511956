import { useModals } from '@/compositions/modals'
const { openModal } = useModals()

type UseWorkspace = () => {
  // Users
  openUserLogOutModal: () => void;
  openDeactivateUserModal: (name: string) => void;
  openActivateUserModal: (name: string) => void;
  openEditUserModal: () => void;
  openUserResetPassModal: () => void;
  openResendInvitationModal: (email: string) => void;
  openCancelInvitationModal: (email: string) => void;
  // Teams
  openTeamModal: () => void;
  openDeleteTeamModal: (selected?: any[], item?: any) => void;
  openEditTeamModal: (value: string) => void;
  openDuplicateTeamModal: () => void;
  // Data
  openMessagesRetentionModal: () => void;
  openDeleteMessagesModal: () => void;
  openDeleteContactsModal: () => void;
  openSendLinkModal: () => void;
  // Roles
  openDuplicateRoleModal: () => void;
  openRolesDeleteModal: (selected: any[]) => void;
  openRolesModal: () => void;
  openEditRoleModal: () => void;
  // Sub accounts
  openDeactivateSubAccountModal: (selected?: any[]) => void;
  openCreateSubAccountModal: () => void;
  openEditSubAccountModal: () => void;
  openMakeDefaultModal: () => void;
  openAddUsersModal: () => void;
  openDeactivateUsersModal: (selected: any[]) => void;
  openRemoveUsersSubAccountModal: (users: any[]) => void;
}

const useWorkspace: UseWorkspace = () => {
  const openSendLinkModal = () => openModal('singleInput', {
    modalTitle: 'Send the download link to an email',
    label: 'Email address',
    value: 'r.overrit@textmagic.com',
    btnText: 'Send',
    description: 'Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.',
  })
  const openDeleteMessagesModal = () => openModal('singleInput', {
    modalTitle: 'Delete all workspace messages',
    label: 'Password',
    placeholder: 'Enter password',
    btnColor: 'error',
    type: 'password',
    btnText: 'Delete',
    description: [
      'To delete all workspace messages, please enter ',
      {
        text: 'your account',
        style: 'semi-bold',
      },
      ' password in the input field.',
    ],
  })
  const openCreateSubAccountModal = () => openModal('workspaceSubAccount')
  const openEditSubAccountModal = () => openModal('workspaceSubAccount', { edit: true })
  const openDeactivateSubAccountModal = (selected?: any[]) => openModal('workspaceDeactivateSubAccount', { selected: selected })
  const openDeleteContactsModal = () => openModal('singleInput', {
    modalTitle: 'Delete all workspace contacts',
    label: 'Password',
    placeholder: 'Enter password',
    btnColor: 'error',
    type: 'password',
    btnText: 'Delete',
    description: [
      'If you delete workspace contacts, you will not be able to restore them later. To delete all workspace contacts, please enter ',
      {
        text: 'your account password',
        style: 'semi-bold',
      },
      ' in the input field.',
    ],
  })
  const openMessagesRetentionModal = () => openModal('singleSelect', {
    modalTitle: 'Messages retention period',
    label: 'Period',
    options: ['1 year', '2 years', '3 years', '5 years', '10 years'],
    btnText: 'Save changes',
    hint: 'Select how long the workspace messages will be stored.',
  })
  const openActivateUserModal = (name: string) => openModal('confirmation', {
    title: 'Activate user',
    text: [
      {
        text: name,
        style: 'semi-bold',
      },
      ' will be reactivated in their previously set role and teams. Are you sure, you would like to reactivate this user?',
    ],
    btnText: 'Activate',
    btnColor: 'primary',
  })
  const openResendInvitationModal = (email: string) => openModal('singleInput', {
    modalTitle: 'Resend invitation',
    description: [
      'You’re about to resend the invitation email to ',
      {
        text: email,
        style: 'semi-bold',
      },
      '. You can change email address for this user if needed.',
    ],
    label: 'User email address',
    value: 'ruth.agent@textmagic.com',
  })
  const openCancelInvitationModal = (email: string) => openModal('confirmation', {
    title: 'Cancel invitation',
    text: [
      'By cancelling invitation, the invited user ',
      {
        text: email,
        style: 'semi-bold',
      },
      ' will not be able to set up their account in your workspace and will be removed from the list of users.\n\n',
      'Are you sure you would like to cancel the invitation?',
    ],
    btnText: 'Cancel invitation',
    btnColor: 'red',
  })
  const openUserLogOutModal = () => openModal('confirmation', {
    title: 'Log out from all devices',
    text: 'By confirming this action, the selected users will be logged out from all devices. Are you sure you would like to proceed?',
    btnText: 'Log out from all devices',
    btnColor: 'primary',
  })
  const openEditUserModal = () => openModal('workspaceEditUser')
  const openUserResetPassModal = () => openModal('confirmation', {
    title: 'Reset password by email',
    text: 'An email with a password reset link will be sent to the user’s email address. After following the link, the user will be prompted to create a new password.',
    btnText: 'Reset password',
  })
  const openDeactivateUserModal = (name: string) => openModal('confirmation', {
    title: 'Deactivate user',
    text: [
      {
        style: 'semi-bold',
        text: name,
      },
      ' will be deactivated and logged out from this workspace. This user will lose access to this workspace and their data.\n\nAre you sure you would like to continue?',
    ],
    btnText: 'Deactivate',
    btnColor: 'error',
  })
  const openDeactivateUsersModal = (selected: any[]) => openModal('confirmation', {
    title: 'Deactivate user',
    text: [
      {
        style: 'semi-bold',
        text: `${selected.length} users`,
      },
      ' will be deactivated and logged out from this workspace. This user will lose access to this workspace and their data.\n\nAre you sure you would like to continue?',
    ],
    btnText: 'Deactivate',
    btnColor: 'error',
  })
  const openTeamModal = () => {
    openModal('workspaceTeam')
  }
  const openEditTeamModal = (value: string) => {
    openModal('workspaceTeam', {
      modalTitle: 'Edit team',
      prefillValue: value,
      modalButtonText: 'Save',
    })
  }
  const openDeleteTeamModal = (selected?: any[], item?: any) => {
    if (!item && (!selected || !selected!.length)) {
      item = { name: 'General', totalUsers: 20 }
    }

    openModal('workspaceDeleteTeams', {
      selectedTeam: item,
      selected: selected,
    })
  }
  const openDuplicateTeamModal = () => {
    openModal('duplicate', {
      itemName: 'team',
      originalName: 'Example team',
      showCheckbox: true,
    })
  }
  const openMakeDefaultModal = () => {
    openModal('confirmation', {
      title: 'Make sub-account default',
      text: [
        'When you log in to your Textmagic account, you will be automatically logged in to ',
        {
          text: 'Adela Test’s sub-account',
          style: 'semi-bold',
        },
        ' by default.\n\n Are you sure, you want to make this sub-account a default one?',
      ],
      btnText: 'Make default',
      btnColor: 'primary',
    })
  }
  const openRemoveUsersSubAccountModal = (users: any[]) => {
    const userText = users.length > 1 ? `${users.length} users` : users[0].person.fullName

    openModal('confirmation', {
      title: `Remove user${users.length > 1 ? 's' : ''} from sub-account`,
      text: [
        'Are you sure you would like to ',
        {
          text: userText,
          style: 'semi-bold',
        },
        ' from the sub-account “',
        {
          text: '512JCAcenter',
          style: 'semi-bold',
        },
        '”? This user will not be able to access any data on this sub account.',
      ],
      btnColor: 'error',
      btnText: 'Remove',
    })
  }
  const openDuplicateRoleModal = () => {
    openModal('duplicate', {
      itemName: 'role',
      originalName: 'Example role',
    })
  }
  const openEditRoleModal = () => {
    openModal('singleInput', {
      modalTitle: 'Rename role',
      btnText: 'Save changes',
      label: 'Role name',
      value: 'Supervisor',
      placeholder: 'Enter role name',
    })
  }
  const openAddUsersModal = () => openModal('workspaceUsersSubAccount')

  const openRolesModal = () => {
    openModal('singleInput', {
      modalTitle: 'New role',
      btnText: 'Save',
      label: 'Role name',
      hint: 'You will be able to set up the permissions for this role in the next step.',
      placeholder: 'Enter role name',
    })
  }

  const openRolesDeleteModal = (selected: any) => {
    openModal('workspaceRolesDelete', {
      selected: selected,
    })
  }

  return {
    openDeactivateUsersModal,
    openRolesModal,
    openRolesDeleteModal,
    openDeleteTeamModal,
    openRemoveUsersSubAccountModal,
    openDuplicateTeamModal,
    openDuplicateRoleModal,
    openTeamModal,
    openEditTeamModal,
    openUserLogOutModal,
    openUserResetPassModal,
    openEditUserModal,
    openDeactivateUserModal,
    openCancelInvitationModal,
    openActivateUserModal,
    openMessagesRetentionModal,
    openDeleteContactsModal,
    openDeleteMessagesModal,
    openEditRoleModal,
    openSendLinkModal,
    openResendInvitationModal,
    openDeactivateSubAccountModal,
    openCreateSubAccountModal,
    openEditSubAccountModal,
    openMakeDefaultModal,
    openAddUsersModal,
  }
}

export default useWorkspace
