
import { defineComponent } from 'vue'
import TmDropdownVisibility from '@/components/shared/dropdowns/TmDropdownVisibility.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'TmDropdownColumns',
  components: { TmButton, TmDropdownVisibility, TmTooltip },
  props: {
    disabled: {
      type: Boolean,
    },
    columnsLabel: {
      type: String,
      default: 'Columns',
    },
    showButtonText: {
      type: Boolean,
    },
  },
  setup() {
    const { isLgMax } = useBreakpoints()

    return {
      isLgMax,
    }
  },
})
